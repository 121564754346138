<template>
  <v-card>
    <div v-if="!startQuestions && !completed">
      <v-card-title>{{ questionnaire.name }}</v-card-title>
      <v-card-subtitle v-if="questionnaire.description">{{
        $languageProcessor.translate(questionnaire.description)
      }}</v-card-subtitle>
      <v-divider class="mx-5"></v-divider>
      <div class="d-flex flex-row-reverse mt-5 pr-5">
        <v-btn @click="startQuestions = true" class="mb-5" color="primary">
          {{ $t("next") }}
        </v-btn>
      </div>
    </div>
    <v-form
      v-else-if="startQuestions"
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-container>
        <div class="my-2">
          <div class="subtitle-1">
            {{ $languageProcessor.translate(questionnaire.questions[currentIndex].title)}}
          </div>
          <div
            v-if="questionnaire.questions[currentIndex].note"
            class="caption"
          >
            {{ questionnaire.questions[currentIndex].note }}
          </div>
        </div>

        <v-text-field
          v-if="questionnaire.questions[currentIndex].questionType == 'text'"
          label="Answer Here"
          outlined
          v-model="questionnaire.questions[currentIndex].answer"
        ></v-text-field>

        <v-radio-group
          v-if="questionnaire.questions[currentIndex].questionType == 'radio'"
          v-model="questionnaire.questions[currentIndex].answer"
          :key="questionnaire.questions[currentIndex]._id"
        >
          <v-radio
            v-for="option in questionnaire.questions[currentIndex].options"
            :key="option"
            :label="option"
            :value="option"
          ></v-radio>
        </v-radio-group>

        <v-select
          v-if="questionnaire.questions[currentIndex].questionType == 'checkbox'"
          chips
          multiple
          v-model="questionnaire.questions[currentIndex].answer"
          :items="questionnaire.questions[currentIndex].options"
          label="Answer Here"
          outlined
        >
        </v-select>

        <div class="d-flex flex-row-reverse mt-5 pr-5">
          <v-btn @click="nextQuestion" class="mb-5" color="primary">{{
            $t("next")
          }}</v-btn>
        </div>

      </v-container>
    </v-form>
    <div v-if="completed">
      <v-card-title>{{ $t("completed") }}!</v-card-title>
      <v-card-subtitle>{{ $t("survey_submitted") }}</v-card-subtitle>
    </div>

    <v-snackbar
      :timeout="2000"
      v-model="questionRequiredSnackbar"
      color="blue-grey"
      rounded="pill"
      vertical
    >
      Question Is Required
    </v-snackbar>
  </v-card>
</template>
<script>
import { DataService } from "../../services/DataService";

const dataService = new DataService();
export default {
  data() {
    return {
      questionnaire: {},
      currentIndex: 0,
      startQuestions: false,
      completed: false,
      questionRequiredSnackbar: false,
      valid: true
    };
  },
  methods: {
    nextQuestion() {
      // if question is required and no answer is given, show snackbar
      this.questionRequiredSnackbar = false;
      if (this.questionnaire.questions[this.currentIndex].required &&
        (!this.questionnaire.questions[this.currentIndex].answer ||
          this.questionnaire.questions[this.currentIndex].answer.length === 0)
      ) {
        this.questionRequiredSnackbar = true;
        return;
      }

      //piping (still need to take care of checkbox answers)
      if (
        this.questionnaire.questions[this.currentIndex].piping &&
        this.questionnaire.questions[this.currentIndex].piping.condition
      ) {
        let questionIdToSkip = null;
        // check each condition
        switch (
          this.questionnaire.questions[this.currentIndex].piping.condition
        ) {
          case "equals":
            if (
              this.questionnaire.questions[this.currentIndex].answer ==
              this.questionnaire.questions[this.currentIndex].piping.answer
            )
              questionIdToSkip = this.questionnaire.questions[this.currentIndex]
                .piping.questionIdToSkip;
            break;

          case "notEquals":
            if (
              this.questionnaire.questions[this.currentIndex].answer !=
              this.questionnaire.questions[this.currentIndex].piping.answer
            )
              questionIdToSkip = this.questionnaire.questions[this.currentIndex]
                .piping.questionIdToSkip;
            break;

          case "greaterThan":
            if (
              this.questionnaire.questions[this.currentIndex].answer >
              this.questionnaire.questions[this.currentIndex].piping.answer
            )
              questionIdToSkip = this.questionnaire.questions[this.currentIndex]
                .piping.questionIdToSkip;
            break;

          case "lessThan":
            if (
              this.questionnaire.questions[this.currentIndex].answer <
              this.questionnaire.questions[this.currentIndex].piping.answer
            )
              questionIdToSkip = this.questionnaire.questions[this.currentIndex]
                .piping.questionIdToSkip;
            break;

          case "greaterThanOrEquals":
            if (
              this.questionnaire.questions[this.currentIndex].answer >=
              this.questionnaire.questions[this.currentIndex].piping.answer
            )
              questionIdToSkip = this.questionnaire.questions[this.currentIndex]
                .piping.questionIdToSkip;
            break;

          case "lessThanOrEquals":
            if (
              this.questionnaire.questions[this.currentIndex].answer <=
              this.questionnaire.questions[this.currentIndex].piping.answer
            )
              questionIdToSkip = this.questionnaire.questions[this.currentIndex]
                .piping.questionIdToSkip;
            break;

          // contains and notContains are for radio answers
          case "contains":
            if (
              this.questionnaire.questions[this.currentIndex].piping.answer.includes(
                this.questionnaire.questions[this.currentIndex].answer
              )
            )
              questionIdToSkip = this.questionnaire.questions[this.currentIndex]
                .piping.questionIdToSkip;
            break;

          case "notContains":
            if (
              !this.questionnaire.questions[this.currentIndex].piping.answer.includes(
                this.questionnaire.questions[this.currentIndex].answer
              )
            )
              questionIdToSkip = this.questionnaire.questions[this.currentIndex]
                .piping.questionIdToSkip;
            break;

          // containsAny, containsAll, notContainAny, notContainAll are for checkbox answers
          case "containsAny":
            if (
              this.questionnaire.questions[
                this.currentIndex
              ].piping.answer.some((option) =>
                this.questionnaire.questions[this.currentIndex].answer.includes(
                  option
                )
              )
            )
              questionIdToSkip = this.questionnaire.questions[this.currentIndex]
                .piping.questionIdToSkip;
            break;

          case "containsAll":
            if (
              this.questionnaire.questions[
                this.currentIndex
              ].piping.answer.every((option) =>
                this.questionnaire.questions[this.currentIndex].answer.includes(
                  option
                )
              )
            )
              questionIdToSkip = this.questionnaire.questions[this.currentIndex]
                .piping.questionIdToSkip;
            break;

          case "notContainAny":
            if (
              !this.questionnaire.questions[
                this.currentIndex
              ].piping.answer.some((option) =>
                this.questionnaire.questions[this.currentIndex].answer.includes(
                  option
                )
              )
            )
              questionIdToSkip = this.questionnaire.questions[this.currentIndex]
                .piping.questionIdToSkip;
            break;

          case "notContainAll":
            if (
              !this.questionnaire.questions[
                this.currentIndex
              ].piping.answer.every((option) =>
                this.questionnaire.questions[this.currentIndex].answer.includes(
                  option
                )
              )
            )
              questionIdToSkip = this.questionnaire.questions[this.currentIndex]
                .piping.questionIdToSkip;
            break;
        }

        // if question to skip is found, remove it from questions array
        if (questionIdToSkip)
          this.questionnaire.questions = this.questionnaire.questions.filter(
            (question) => question._id !== questionIdToSkip
          );
      }

      // if last question, submit
      if (this.currentIndex == this.questionnaire.questions.length - 1) {
        this.submitQuestions();
        this.completed = true;
        this.startQuestions = false;
        return;
      }
       this.currentIndex++;
    },

    submitQuestions() {
      this.$setLoader();
      let response = [];
      this.questionnaire.questions.forEach((question) => {
        response.push({
          _id: question._id,
          title: question.title,
          questionType: question.questionType,
          answer: question.answer,
        });
      });
      dataService.submitUserProfileData(this.$store.state.token, {
        userProfilingId: this.$route.params.id,
        response,
      });
      this.$clearLoader();
    },
    
    fetchQuestionnaire() {
      let dataToSend = {
        userId: this.$store.state.userProfile._id,
        userProfilingId: this.$route.params.id,
      };
      this.$setLoader();
      dataService.getUserProfileQuestions(this.$store.state.token, dataToSend).then((res) => {
          this.questionnaire = res.data;
          this.questionnaire.questions.forEach((question) => {
            if (question.questionType == "text" || question.questionType == "radio") {
              question.answer = "";
            } else if (question.questionType == "checkbox") {
              question.answer = [];
            } else {
              question.answer = "";
            }
          });
        });
      this.$clearLoader();
    },
  },
  mounted() {
    this.fetchQuestionnaire();
  },
};
</script>
  
<style scoped>
::v-deep .v-snack__wrapper {
  min-width: 180px;
}
</style>
